import type IPlayer from '@etf1-interne/player'
import type { PlayerCompanionEvent } from '../../../types/player'
import { useEffect, useRef, useState, useContext, useMemo, useCallback, useId } from 'react'
import { useAmp } from 'next/amp'

import { useScrollDirection } from '../../../hook/useScrollDirection'
import { usePlayerManager } from '../../../contexts/playerManager'
import {
  END,
  COMINGNEXT_NEXT_CLICK,
  ERROR,
  AD_COMPANION,
  AD_ERROR,
  AD_END,
  AD_SKIP,
  CONTROLS_SHOW,
  CONTROLS_HIDE,
  FULLSCREEN_ENTER,
  FULLSCREEN_EXIT,
} from '../../../constants/playerEvents'
import {
  DIRECT_BANNER_HEIGHT,
  PLAYER_PIP_HEIGHT,
  PLAYER_PIP_MIN_HEIGHT,
  PLAYER_PIP_MIN_WIDTH,
  PLAYER_PIP_WIDTH,
  PLAYER_RATIO_PIP,
} from '../../../constants/player'
import { CLICK } from '../../../constants/tms'
import { ButtonBanner } from '../ButtonBanner'
import { BlockerLayout } from '../../molecules/BlockerLayout'
import { LciLogo } from '../Icon/LciLogo'
import { useBlockerDetector } from '../../../hook/useBlockerDetector'
import { useViewport } from '../../../hook/useViewport'
import { allowedPipDirection } from '../../../helpers/player'
import { isIOS } from '../../../helpers/client'
import { mixins, theme } from '../../../styles'
import { PlayerAmp } from '../PlayerAmp'
import { Player } from '../Player'
import { useCMP } from '../../../hook/useCMP'
import { useUser } from '../../../hook/useUser'
import { PlayerBlock } from '../../molecules/PlayerBlock'
import { useIntersection } from '../../../hook/useIntersection'
import { PlaceholderLogo } from '../Icon/TF1Info/PlaceholderLogo'
import { SVGIcon } from '../SVGIcon'
import { useFullscreenOnRotate } from './hooks/useFullscreenOnRotate'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export interface IPlayerVideo {
  autoplay?: boolean
  autoplayMuted?: boolean
  noAds?: boolean
  comingNext?: boolean
  containerId: string
  videoId: string
  onMediaEnd?: () => void
  onComingNextClick?: () => void
  onMediaError?: () => void
  onAdCompanion?: (data: any) => void
  withDirectBanner?: boolean
  withPip?: boolean
  pipDirection?: 'all' | 'down'
  isHiddenMobile?: boolean
  liveTimeShift?: number
  infoComingNext?: {
    thumb?: string
    title?: string
    description?: string
    duration?: number | string
    videoId?: number | string
  }
  placeholderTheme?: 'light' | 'dark'
  floatOnPlay?: boolean
  introductionVideoId?: string
  restricted?: boolean
  restrictionWordings?: {
    signInTitle?: string
    signUpButton?: string
    signInButton?: string
  }
  fullScreenOnRotate?: boolean
}

export function PlayerVideo(props: IPlayerVideo): JSX.Element {
  const isAmp = useAmp()
  const { isLoggedIn, loading } = useUser()

  if (props.restricted && (isAmp || loading || !isLoggedIn)) {
    return <PlayerBlock isLoading={loading} wordings={props.restrictionWordings} />
  }

  return isAmp ? (
    <PlayerAmp videoId={props.videoId} autoplay={props.autoplay} />
  ) : (
    <PlayerVideoStandard {...props} />
  )
}

/**
 * documentation: (http://staging-player.tf1.fr/4.15.0/sample/index.html)
 **/
export function PlayerVideoStandard({
  containerId,
  videoId,
  autoplay = true,
  autoplayMuted = true,
  noAds,
  onMediaEnd,
  onComingNextClick,
  onMediaError,
  onAdCompanion,
  comingNext,
  withDirectBanner,
  withPip = false,
  pipDirection = 'all',
  isHiddenMobile = false,
  infoComingNext,
  placeholderTheme = 'dark',
  floatOnPlay,
  introductionVideoId,
  liveTimeShift,
  fullScreenOnRotate,
}: IPlayerVideo): JSX.Element {
  const { isMobile } = useViewport()

  const { subtype } = useContext(ApiResponseContext)
  const { hit } = useContext(TagCommanderContext)
  const videoPlayerRef = useRef<IPlayer>(null)
  const playerLogEvent = useRef(null)
  const videoPlayerBlock = useRef(null)
  const floatingPlayerRef = useRef(null)
  const floatingButtonTimeout = useRef(null)
  const uniqueId = useId()
  const playerId = `${containerId}-${uniqueId}`

  const [playerInitialized, setPlayerInitialized] = useState<boolean>(false)
  const [videoSize, setVideoSize] = useState({ height: 0, width: 0, left: 0 })
  const [heightHeader, setHeightHeader] = useState(85)

  const { scrollDirection: vertical } = useScrollDirection()
  const [scrollOnLeaveView, setScrollOnLeaveView] = useState<string>(vertical)
  const [isDirectionAllowedToFloat, setIsDirectionAllowedToFloat] = useState<boolean>(
    floatOnPlay ? allowedPipDirection(pipDirection, vertical) : false,
  )
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const { consentString } = useCMP()
  const { isActive: adBlockDetected } = useBlockerDetector()
  const [isCloseButtonDisplay, setIsCloseButtonDisplay] = useState<boolean>(false)

  const { inView } = useIntersection({
    ref: videoPlayerBlock.current,
    onEnter: () => {
      setIsDirectionAllowedToFloat(allowedPipDirection(pipDirection, vertical))
    },
    onLeave: () => {
      setScrollOnLeaveView(vertical)
      setIsDirectionAllowedToFloat(allowedPipDirection(pipDirection, vertical))
    },
    options: {
      rootMargin: '-50px 0px 0px 0px',
      threshold: 1,
    },
  })

  const canFloat = useMemo(
    () =>
      isDirectionAllowedToFloat && // if video is allowed to float in this direction
      !inView && // if video position is not in view
      withPip && // if the video is allowed to float
      !isFullscreen, // if the video is not in fullscreen
    [isDirectionAllowedToFloat, inView, withPip, isFullscreen],
  )

  const { isFloating, closePlayer } = usePlayerManager(
    playerId,
    videoPlayerRef,
    playerInitialized,
    canFloat,
  )

  useFullscreenOnRotate(videoPlayerRef, fullScreenOnRotate)

  const setComingNext = useCallback(() => {
    if (infoComingNext && comingNext) {
      videoPlayerRef.current?.setComingNext?.(infoComingNext)
    }
  }, [infoComingNext, comingNext])

  const handleCompanionEvent = useCallback(
    (event: PlayerCompanionEvent) => {
      const data = event?.value?.adCompanions[0]
      onAdCompanion?.({
        ...data,
        player: videoPlayerRef.current,
      })
    },
    [onAdCompanion],
  )

  const handleCloseCompanion = useCallback(
    () => handleCompanionEvent?.(null),
    [handleCompanionEvent],
  )

  const handleAdError = useCallback(
    ({ value }: { value: { errorCode: string } }) => {
      if (value?.errorCode === '_e_invalid-slot') {
        return
      }
      handleCompanionEvent?.(null)
    },
    [handleCompanionEvent],
  )

  const handleClosePip = (event: any) => {
    // Add ad values to the click event if available
    const adValues = Object.entries(window?.tc_vars || {}).reduce((acc, [key, value]) => {
      if (key.startsWith('ad_')) {
        acc[key] = value
      }
      return acc
    }, {})
    playerLogEvent.current?.(CLICK, { value: 'player-croix-fermeture', event: adValues }, true)
    closePlayer()
    event.preventDefault()
    event.stopPropagation()
  }

  const handlePlayerInit = useCallback(() => setPlayerInitialized(true), [setPlayerInitialized])

  const handleBannerClick = () => {
    hit(
      {
        screen_clickableElementName: `bouton-direct-lci`,
      },
      { isClickEvent: true },
    )
  }

  useEffect(() => {
    if (isHiddenMobile && videoPlayerRef.current && isMobile) {
      videoPlayerRef.current?.pause(true) // true for user action
    }
  }, [isHiddenMobile, isMobile])

  // Define all event listeners for the video player that don't depends on external props
  useEffect(() => {
    if (playerInitialized) {
      const { clientHeight, clientWidth, offsetLeft } = videoPlayerBlock.current
      setVideoSize({ height: clientHeight, width: clientWidth, left: offsetLeft })
    }
  }, [playerInitialized])

  useEffect(() => {
    if (onMediaEnd && videoPlayerRef.current) {
      videoPlayerRef.current?.on?.(END, onMediaEnd)
      return () => {
        videoPlayerRef.current?.off?.(END, onMediaEnd)
      }
    }
  }, [playerInitialized, onMediaEnd])

  useEffect(() => {
    if (onMediaError && videoPlayerRef.current) {
      videoPlayerRef.current?.on?.(ERROR, onMediaError)
      return () => {
        videoPlayerRef.current?.off?.(ERROR, onMediaError)
      }
    }
  }, [playerInitialized, onMediaError])

  useEffect(() => {
    if (onComingNextClick && videoPlayerRef.current) {
      videoPlayerRef.current?.on?.(COMINGNEXT_NEXT_CLICK, onComingNextClick)
      return () => {
        videoPlayerRef.current?.off?.(COMINGNEXT_NEXT_CLICK, onComingNextClick)
      }
    }
  }, [playerInitialized, onComingNextClick])

  useEffect(() => {
    if (handleCompanionEvent && videoPlayerRef.current) {
      videoPlayerRef.current?.on?.(AD_COMPANION, handleCompanionEvent)
      videoPlayerRef.current?.on?.(AD_ERROR, handleAdError)
      videoPlayerRef.current?.on?.(AD_END, handleCloseCompanion)
      videoPlayerRef.current?.on?.(AD_SKIP, handleCloseCompanion)
      videoPlayerRef.current?.on?.(ERROR, handleCloseCompanion)
      return () => {
        videoPlayerRef.current?.off?.(AD_COMPANION, handleCompanionEvent)
        videoPlayerRef.current?.off?.(AD_ERROR, handleAdError)
        videoPlayerRef.current?.off?.(AD_END, handleCloseCompanion)
        videoPlayerRef.current?.off?.(AD_SKIP, handleCloseCompanion)
        videoPlayerRef.current?.off?.(ERROR, handleCloseCompanion)
      }
    }
  }, [playerInitialized, handleCompanionEvent, handleAdError, handleCloseCompanion])

  useEffect(() => {
    function handleFullscreenEnter() {
      setIsFullscreen(true)
    }

    function handleFullscreenExit() {
      setIsFullscreen(false)
    }

    // This is a hack to make sure the video in fullscreen is not broken on iOS
    // the player lib handle this like ****
    // http://github.dedale.tf1.fr/etf1-players/Player-Web/blob/a4d5d1ef4837451f602ebfcb47631135990a1a8f/src/sdk/index.js#L78
    if (isIOS() && videoPlayerRef.current) {
      videoPlayerRef.current?.on?.(FULLSCREEN_ENTER, handleFullscreenEnter)
      videoPlayerRef.current?.on?.(FULLSCREEN_EXIT, handleFullscreenExit)
      return function cleanFullscreenListener() {
        videoPlayerRef.current?.off?.(FULLSCREEN_ENTER, handleFullscreenEnter)
        videoPlayerRef.current?.off?.(FULLSCREEN_EXIT, handleFullscreenExit)
      }
    }
  }, [playerInitialized])

  useEffect(
    () =>
      function cleanup() {
        handleCloseCompanion()
      },
    [handleCloseCompanion],
  )

  useEffect(
    function handleCloseButtonOnFloating() {
      function handleShowCloseButton() {
        setIsCloseButtonDisplay(true)
      }

      function handleHideCloseButton() {
        setIsCloseButtonDisplay(false)
        clearTimeout(floatingButtonTimeout.current)
      }

      // Add a delay to hide the close button
      // If the user active the controls with the touch
      function handleHideWithDelay() {
        floatingButtonTimeout.current = setTimeout(() => {
          setIsCloseButtonDisplay(false)
        }, 3000)
      }

      if (playerInitialized && isFloating) {
        videoPlayerRef.current?.on?.(CONTROLS_SHOW, handleShowCloseButton)
        videoPlayerRef.current?.on?.(CONTROLS_HIDE, handleHideWithDelay)
        floatingPlayerRef.current?.addEventListener('mouseleave', handleHideCloseButton)

        return function clean() {
          clearTimeout(floatingButtonTimeout.current)
          videoPlayerRef.current?.off?.(CONTROLS_SHOW, handleShowCloseButton)
          videoPlayerRef.current?.off?.(CONTROLS_HIDE, handleHideWithDelay)
          floatingPlayerRef.current?.removeEventListener('mouseleave', handleHideCloseButton)
        }
      }
    },
    [playerInitialized, isFloating],
  )

  useEffect(() => {
    const HeaderContainer =
      subtype === 'home'
        ? document.querySelector('.Header__Home')
        : document.querySelector('.Header__Container')
    const headerHeight = HeaderContainer?.clientHeight ?? theme.layout.desktop.header.height
    setHeightHeader(headerHeight + 10)
  }, [scrollOnLeaveView])

  return (
    <>
      <div ref={videoPlayerBlock} className="PlayerVideo">
        <div
          className={[
            isFloating ? `container__Pip ${withDirectBanner ? 'container__Pip_Direct' : ''}` : '',
            scrollOnLeaveView === 'up' ? 'container__Pip__LeaveUp' : 'container__Pip__LeaveDown',
          ].join(' ')}
          ref={floatingPlayerRef}
        >
          {isFloating && isCloseButtonDisplay ? (
            <button
              className="container__Pip__Close flex justify-center items-center"
              onClick={handleClosePip}
            >
              <SVGIcon name="close" size={20} primaryColor={theme.cssVars.white} />
            </button>
          ) : null}
          <div className="PlayerVideo__iframe">
            <div className="PlayerVideo__Default__Container flex justify-center items-center">
              <PlaceholderLogo theme={placeholderTheme} />
            </div>
            {adBlockDetected ? (
              <BlockerLayout id={playerId} />
            ) : playerId ? (
              <Player
                ref={(ref) => {
                  videoPlayerRef.current = ref?.player
                  playerLogEvent.current = ref?.logEvent
                }}
                playerId={playerId}
                introductionVideoId={introductionVideoId}
                videoId={videoId}
                autoplay={autoplay}
                autoplayMuted={autoplayMuted}
                noAds={noAds}
                consent={{ consentString }}
                hasComingNext={comingNext}
                onPlayerInit={handlePlayerInit}
                onVideoChange={setComingNext}
                onPlayerReady={setComingNext}
                liveTimeShift={liveTimeShift}
              />
            ) : null}
            {withDirectBanner && (
              <ButtonBanner
                backgroundColor={theme.cssVars.red}
                href="/direct/"
                textColor={theme.cssVars.white}
                onClick={handleBannerClick}
              >
                <>
                  <SVGIcon name="direct" primaryColor={theme.cssVars.white} size={13} />
                  <span>Regarder le direct</span>
                  <LciLogo />
                </>
              </ButtonBanner>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes slideToTop {
          to {
            transform: translate(var(--player-pip-left));
            top: ${heightHeader}px;
          }
        }

        @keyframes transformPipPlayerMobile {
          to {
            width: max(${PLAYER_PIP_MIN_WIDTH}px, 50vw);
            height: max(${PLAYER_PIP_MIN_HEIGHT}px, calc(50vw * ${PLAYER_RATIO_PIP / 100}));
          }
        }

        @keyframes transformPipPlayerMobileDirect {
          to {
            width: max(${PLAYER_PIP_MIN_WIDTH}px, 50vw);
            height: calc(
              max(${PLAYER_PIP_MIN_HEIGHT}px, calc(50vw * ${PLAYER_RATIO_PIP / 100})) +
                ${DIRECT_BANNER_HEIGHT}px
            );
          }
        }

        @keyframes transformPipPlayerDesktop {
          to {
            width: ${PLAYER_PIP_WIDTH}px;
            height: ${PLAYER_PIP_HEIGHT}px;
          }
        }

        @keyframes transformPipPlayerDesktopDirect {
          to {
            width: ${PLAYER_PIP_WIDTH}px;
            height: ${PLAYER_PIP_HEIGHT + DIRECT_BANNER_HEIGHT}px;
          }
        }
      `}</style>

      <style jsx>{`
        .container__Pip {
          width: ${videoSize.width}px;
          height: ${videoSize.height}px;
        }

        .container__Pip.container__Pip__LeaveDown {
          transform: translate(${videoSize.left}px);
          top: ${heightHeader}px;
        }

        .container__Pip.container__Pip__LeaveUp {
          transform: translate(${videoSize.left}px);
          top: calc(100% - ${videoSize.height}px);
        }
      `}</style>

      <style jsx>{`
        .PlayerVideo :global(.Player) {
          height: ${withDirectBanner ? `calc(100% - ${DIRECT_BANNER_HEIGHT}px)` : '100%'};
        }

        .PlayerVideo:before {
          padding-top: ${withDirectBanner
            ? `calc(${PLAYER_RATIO_PIP}% + ${DIRECT_BANNER_HEIGHT}px)`
            : `${PLAYER_RATIO_PIP}%`};
        }
      `}</style>

      <style jsx>{`
        :global(:root) {
          --player-pip-left: 10px;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          --player-pip-left: ${theme.block.marginLR}px;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .container__Pip {
          position: fixed;
          z-index: 1000;
          left: 0;

          animation-duration: 0s;
          animation-fill-mode: forwards;
          animation-name: transformPipPlayerMobile, slideToTop;

          border-radius: 4px;
          overflow: hidden;
        }

        @media ${mixins.mediaQuery.tablet} {
          .container__Pip {
            animation-duration: 1s;
          }
        }

        .container__Pip.container__Pip_Direct {
          animation-name: transformPipPlayerMobileDirect, slideToTop;
        }

        .container__Pip__Close {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1001;
          border: none;
          padding: 2px;
          background-color: ${theme.cssVars.deepBlue};
          animation: fadeIn 0.5s;
        }

        .container__Pip__Close :global(svg) {
          width: 20px;
          height: 20px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .container__Pip {
            animation-name: transformPipPlayerDesktop, slideToTop;
          }

          .container__Pip.container__Pip_Direct {
            animation-name: transformPipPlayerDesktopDirect, slideToTop;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          :global(:root) {
            --player-pip-left: max(
              10px,
              calc(100vw / 3 - ${PLAYER_PIP_WIDTH + theme.block.marginLR}px)
            );
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          :global(:root) {
            --player-pip-left: max(
              10px,
              calc(
                100vw / 2 - ${PLAYER_PIP_WIDTH}px - ${theme.layout.desktop.body.width / 2}px -
                  ${theme.block.marginLR * 2}px
              )
            );
          }
        }

        .PlayerVideo__Default__Container {
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;

          align-content: center;
          background-color: ${placeholderTheme === 'dark'
            ? theme.cssVars.deepBlue
            : theme.cssVars.lightBlue};
        }

        .PlayerVideo {
          width: 100%;
          position: relative;
        }

        .PlayerVideo:before {
          content: '';
          display: block;
        }

        .PlayerVideo__iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        .PlayerVideo :global(.Player),
        .PlayerVideo__iframe :global(.BlockerLayout) {
          width: 100%;
          position: absolute;
          top: 0;
        }

        .PlayerVideo :global(.Player) :global(iframe) {
          border: none;
        }

        .PlayerVideo__iframe :global(.ButtonBanner) {
          position: absolute;
          height: ${DIRECT_BANNER_HEIGHT}px;
          bottom: 0;
          left: 0;
          padding: 0;

          justify-content: center;

          font-weight: 700;
          font-size: 15px;
        }

        .PlayerVideo__iframe :global(.ButtonBanner > *) {
          display: flex;
          margin-left: 8px;
        }

        .PlayerVideo__iframe :global(.ButtonBanner > span) {
          padding-top: 4px;
          text-transform: uppercase;
        }

        .PlayerVideo__iframe :global(.ButtonBanner > *:first-child) {
          margin-left: 0;
        }
      `}</style>
    </>
  )
}
